
/* Add your profile image here */
.Photo{
    background-image: url('https://p.kindpng.com/picc/s/541-5410709_me-clipart-png-me-clip-art-transparent-png.png');
}
/* Projects Photo */
.project1{
    background-image: url('https://i.postimg.cc/NQZRqxFR/News-App-Sharif-Choudhury.png');
    cursor: none;    
}
.project2{
    background-image: url('https://i.postimg.cc/Y22WfnDB/Feedback-Machine-Sharif-Choudhury.png');
    cursor: none;
}
.project3{
    background-image: url('https://i.postimg.cc/qpqGdRLH/Full-Marks-Sharif-Choudhury.png');
    cursor: none;
}
.project4{
    background-image: url('https://i.postimg.cc/d0K774ZX/Catalyst-Sharif-Choudhury.png');
    cursor: none;
}

/*.project5{
    background-image: url('https://via.placeholder.com/400x600.png?text=Project+five');
    cursor: none;
}
.project6{
    background-image: url('https://via.placeholder.com/400x600.png?text=Project+six');
    cursor: none;
}
/*

/* Miscelaneous Activities photo */
.misc1{
    background-image: url('https://i.postimg.cc/rp90XxZr/Design1-Sharif-Choudhury.png');
    cursor: none;
}
.misc2{
    background-image: url('https://i.postimg.cc/mDXPhRsZ/Design2-Sharif-Choudhury.png');
    cursor: none;
}
.misc3{
    background-image: url('https://i.postimg.cc/7PRCkx9m/design3sharifchoudhury.png');
    cursor: none;
}
.misc4{
    background-image: url('https://i.postimg.cc/3K0KgwDv/Laptop-Model-Sharif-Choudhury.png');
    cursor: none;
}
.misc5{
    background-image: url('https://i.postimg.cc/TdrQ14FV/Catalyst-Game-Sw-ord-Sharif-Choudhury.png');
    cursor: none;
}
.misc6{
    background-image: url('https://i.postimg.cc/tyBWkb31/Video-Production-Editing-Sharif-Choudhury.png');
    cursor: none;
}
/* Contact form parallax effect image */
.parallax{
    background-image: url('https://i.postimg.cc/dwXdM9Sw-/background-By-Sharif-Choudhury.png');
}